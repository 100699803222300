import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import HomeLayout from "../components/Home/Home.layout"

const IndexPage = props => {
  const projectsData = useStaticQuery(graphql`
    {
      allProjectsJson {
        edges {
          node {
            type
            data {
              url_name
              content {
                ua {
                  title
                  description
                  short_description
                  seo {
                    title
                    description
                  }
                  cover {
                    alt
                    src {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          layout: FULL_WIDTH
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return <HomeLayout parentProps={props} projectsData={projectsData} />
}

export default IndexPage
