import * as React from "react"
import loadable from "@loadable/component"
import pageContent from "../../content/home.json"

const Layout = loadable(() => import("@common/Layout/Layout"))
const Home = loadable(() => import("@components/Home/Home"))
const SEO = loadable(() => import("@components/Common/SEO/SEO"))

const HomeLayout = props => {
  const parentProps = props.parentProps
  const projectsData = props.projectsData
  const content = pageContent.content[parentProps.pageContext.langKey]

  return (
    <>
      <SEO
        title={content.seo.title}
        description={content.seo.description}
      />
      <Layout
        location={parentProps.location}
        lang={parentProps.pageContext.langKey}
      >
        <Home projects={projectsData.allProjectsJson.edges[0].node.data} />
      </Layout>
    </>
  )
}

export default HomeLayout
